<template>
	<div class="auth-wrapper auth-v1 px-2">
		<div class="auth-inner py-2">

			<b-card class="mb-0">
				<b-link class="brand-logo">
					<h2 class="brand-text text-primary ml-1">
						Blogily
					</h2>
				</b-link>

				<b-card-title class="mb-1 text-center">
					Bienvenue sur Blogily
				</b-card-title>

				<!-- form -->
				<validation-observer
					ref="loginForm"
					#default="{invalid}"
				>
					<b-form
						class="auth-login-form mt-2"
						@submit.prevent
					>
						<small v-if="error" class="text-danger">
							{{ error }}
						</small>
						<b-form-group
							label-for="email"
							label="E-Mail"
						>
							<validation-provider
								#default="{ errors }"
								name="Email"
								rules="required|email"
							>
								<b-form-input
									id="email"
									v-model="userEmail"
									name="login-email"
									:state="errors.length > 0 ? false:null"
									placeholder="johndoe@gmail.com"
									autofocus
									autocomplete="email"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group>
							<div class="d-flex justify-content-between">
								<label for="password">Mot de passe</label>
								<!-- <b-link :to="{name:'auth-forgot-password-v1'}">
									<small>Mot de passe oublié ?</small>
								</b-link> -->
							</div>
							<validation-provider
								#default="{ errors }"
								name="Password"
								rules="required"
							>
								<b-input-group
									class="input-group-merge"
									:class="errors.length > 0 ? 'is-invalid':null"
								>
									<b-form-input
										id="password"
										v-model="password"
										:type="passwordFieldType"
										class="form-control-merge"
										:state="errors.length > 0 ? false:null"
										name="login-password"
										placeholder="Mot de passe"
										autocomplete="current-password"
									/>

									<b-input-group-append is-text>
										<feather-icon
											class="cursor-pointer"
											:icon="passwordToggleIcon"
											@click="togglePasswordVisibility"
										/>
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<!-- checkbox -->
						<b-form-group>
							<b-form-checkbox
								id="remember-me"
								v-model="status"
								name="checkbox-1"
							>
								Se souvenir de moi
							</b-form-checkbox>
						</b-form-group>

						<!-- submit button -->
						<b-button
							variant="primary"
							type="submit"
							block
							:disabled="invalid"
							@click="submit"
						>
							Se connecter
						</b-button>
					</b-form>
				</validation-observer>

				<!-- <b-card-text class="text-center mt-2">
					<span>New on our platform? </span>
					<b-link :to="{name:'auth-register-v1'}">
						<span>Create an account</span>
					</b-link>
				</b-card-text> -->

			</b-card>
		</div>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle,
	BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { auth } from '@/firebase.config'

export default {
	components: {
		// BSV
		BButton,
		BForm,
		BFormInput,
		BFormGroup,
		BCard,
		BCardTitle,
		BLink,
		// BCardText,
		BInputGroup,
		BInputGroupAppend,
		BFormCheckbox,
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			userEmail: '',
			password: '',
			status: '',
			error: '',
			// validation rules
			required,
			email,
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
	},
	created() {
		if (this.$cookies.isKey('userEmail')) {
			this.userEmail = this.$cookies.get('userEmail')
			this.password = this.$cookies.get('password')
			this.status = true
		}
	},
	methods: {
		submit() {
			auth.signInWithEmailAndPassword(
				this.userEmail,
				this.password,
			)
			.then(() => {
				if (this.status) {
					this.$cookies.set('userEmail', this.userEmail)
					this.$cookies.set('password', this.password)
				} else {
					this.$cookies.remove('userEmail')
					this.$cookies.remove('password')
				}
				this.notify('Bienvenue !')
				this.$router.replace({ name: 'home' })
			})
			.catch(err => {
				this.error = err.message
				this.notify(this.error, 'danger')
			})
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
